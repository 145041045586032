import React from 'react'
import styled from 'styled-components'

import { Wrapper } from '../wrapper'
import { Container } from '../container'
import { Heading } from '../heading'
import { StyledLink } from '../link'

import { device  } from '../../../utils/breakpoints'

import { GatsbyImage } from "gatsby-plugin-image"



const StyledWrapper = styled(Wrapper) `
    background: #40916C;
`


const StyledContainer = styled(Container) `
    gap: 2rem;
    justify-items: center;

`

const ContainerText = styled.div `
    display: grid;
    justify-items: center;
    align-content: start;
    gap: 2rem;
`

const StyledHeading = styled(Heading) `
    color: ${({theme}) => theme.colors.white};
`   


export const SmallTitlePage = ({ title }) => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <ContainerText>
                    <StyledHeading h1 as='h1'>{title}</StyledHeading>
                </ContainerText>
            </StyledContainer>
        </StyledWrapper>
    )
}