import React from 'react'
import styled from 'styled-components'

import { Heading } from '../heading'
import { Button } from '../button'

import { device } from '../../../utils/breakpoints'

import { GatsbyImage } from "gatsby-plugin-image"

const ContainerTreatmentItem = styled.article `
    display: grid;
    grid-template-rows: 300px auto;
    gap: 1.5rem;
    padding: 1.5rem;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    width: 100%;
    -webkit-box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03); 
    box-shadow: 0px 3px 6px 3px rgba(0,0,0,0.03);
    max-height: 800px;


`

const ContainerImage = styled.div `
    max-height: 450px;
    height: 100%;

    @media ${device.tablet} { 
        max-height: 300px;

        .image-styles-hero {
            max-height: 300px;
        }
    }

    .image-styles-hero {
        border-radius: 10px;
        max-height: 450px;
    }
`

const Img = styled(GatsbyImage) `
    height: 100%;
    width: 100%;
    max-height: 450px;
    height: 100%;

    @media ${device.tablet} { 
        max-height: 300px;
    }

`


const ContainerText = styled.div `
    height: 100%;
    display: grid;
    grid-template-rows: auto auto;
    align-content: space-between;
    gap: 1rem;

`

const ContainerTopText = styled.div `
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
        font-size: ${({ theme }) => theme.fonts.size.xs};
    }
`

const StyledHeading = styled(Heading) `
    color: ${({ theme }) => theme.colors.green};
    font-weight: 500;
`

const StyledButton = styled(Button) `
    justify-self: center;
    align-self: center;
`




export const TreatmentServices = ({ treatment }) => {

    const {
        id, 
        nazwaZabiegu,
        opisZabiegu,
        slug,
        zdjecieGlowne
    } = treatment

    return (
        <ContainerTreatmentItem>
            <ContainerImage>
                <Img imgClassName="image-styles-hero" title={zdjecieGlowne?.title} alt={zdjecieGlowne?.description} image={zdjecieGlowne?.gatsbyImageData} />
            </ContainerImage>
            <ContainerText>
                <ContainerTopText>
                    <StyledHeading h4 as='h4'>{nazwaZabiegu}</StyledHeading>
                    <p>{opisZabiegu.opisZabiegu}</p>
                </ContainerTopText>
                 <StyledButton to={slug}>Dowiedz się więcej</StyledButton>
            </ContainerText>
        </ContainerTreatmentItem>
    )
}