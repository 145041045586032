import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '../wrapper'
import { Container } from '../container'
import { device  } from '../../../utils/breakpoints'
import { TreatmentServices } from './treatmentServices'


const StyledWrapper = styled(Wrapper) `

`

const StyledContainer = styled(Container) `
    grid-template-rows: repeat(2, auto);
    gap: 2rem;

    span {
        color: ${({ theme }) => theme.colors.green};
    }
`

const ContainerBeautyTreatments = styled.div `
    display: grid;
    gap: 2rem;
    grid-template-columns: auto;


    @media ${device.tablet} { 
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.laptopL} { 
        grid-template-columns: repeat(3, 1fr);
    }

`


export const BeautyTreatments = ({ treatments }) => {
 
    const {
        id, 
        nazwaZabiegu,
        opisZabiegu,
        slug,
        zdjecieGlowne
    } = treatments

    return (
        <StyledWrapper>
            <StyledContainer>
                <ContainerBeautyTreatments>
                    {treatments.map((treatment) => {
                        return (
                            <TreatmentServices 
                                key={treatment.id} 
                                treatment={treatment}
                            />
                        )
                    })}
                </ContainerBeautyTreatments>
            </StyledContainer>
        </StyledWrapper>
    )
}